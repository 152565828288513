import {ToastService} from '../services/toast.service';
export const SuccessToast = (message:string, duration:number = 0) => {
    return ToastService.presentToast(
        message,
        "Okay",
        duration,
        'success'
    );
}

export const ErrorToast = (message:string, duration:number = 0) => {
    return ToastService.presentToast(
        message,
        "Okay",
        duration,
        'danger'
    );
}

